import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

export function SEO({
  description = 'Nanea website',
  lang = 'de',
  meta = [],
  title = 'Nanea',
  image,
}: SEOProps) {
  const { site, website } = useStaticQuery<QueryTypes>(SEOStaticQuery);

  const metaDescription = description || site.siteMetadata.description;

  const pageTitle = title + ' | ' + site.siteMetadata.title;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={pageTitle}
      titleTemplate={pageTitle}

      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: pageTitle,
        },
        {
          property: `og:site_name`,
          content: pageTitle,
        },
        {
          property: `og:image`,
          content: website.logo_url,
        },
        {
          property: `og:image:secure_url`,
          content: website.logo_url,
        },
        {
          property: `og:image:width`,
          content: '945',
        },
        {
          property: `og:image:height`,
          content: '630',
        },
        {
          property: `og:image:alt`,
          content: 'Eder Baumanagement',
        },
        {
          property: `og:image:type`,
          content: 'image/png',
        },
        {
          property: `og:locale`,
          content: 'de_DE',
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.author || ``,
        },
        {
          name: `twitter:title`,
          content: pageTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    />
  );
}

// Types
type SEOProps = {
  description?: string;
  lang?: string;
  meta?: Meta;
  title: string;
  image?: string;
};

type Meta = ConcatArray<PropertyMetaObj | NameMetaObj>;

type PropertyMetaObj = {
  property: string;
  content: string;
};

type NameMetaObj = {
  name: string;
  content: string;
};

type QueryTypes = {
  site: {
    siteMetadata: {
      title: string;
      description: string;
      author: string;
      siteUrl: string;
    };    
  };
  website: {
    logo_url: string;
  };
};

// Queries
const SEOStaticQuery = graphql`
  query {
    site {
      siteMetadata {
        siteUrl
        title
        description
        author
      }      
    }  
    website {
      logo_url
    }  
  }
`;
