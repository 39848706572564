import React from 'react';

import Footer from './footer/Footer';
import HeaderSection from './HeaderSection';
import { SEO } from './Seo';

function Layout (props) {   
  if (props.data == undefined) {
    return <></>
  }
  return (    
    <> 
     <div style={{backgroundColor: `#${props.data.website.color_background}`}} className={`mx-auto flex flex-col h-screen justify-between overflow-x-hidden `}>
        <SEO title={props.data.website.name}/>        
        <HeaderSection {...props}  />
        {props.children}
        <div className='grow mt-0'></div>
        <Footer website={props.data.website} />
      </div>
    </>
  );
};

export default Layout;
