import React from "react";
import Select from "react-select";
import { StaticImage } from "gatsby-plugin-image";
import {I18nextContext, Link, useI18next} from 'gatsby-plugin-react-i18next';

const options_data = {
    'en': { value: "en", label: <StaticImage src={'../images/flag-en.png'} alt='English'  className="object-contain h-4 w-6 "/>},
    'de': { value: "de", label: <StaticImage src={'../images/flag-de.png'} alt='German'   className="object-contain h-4 w-6 "/>},
    'it': { value: "it", label: <StaticImage src={'../images/flag-it.png'} alt='Italy'    className="object-contain h-4 w-6 " /> }
};
let options ={}; 
    

const HeaderSection = (props) => {
    ["de","en","it"].forEach((l)=>{
        if(props.data.website.supported_lang[l]){
            options[l] = options_data[l];
        }
    });

    const {languages, changeLanguage} = useI18next();
    const context = React.useContext(I18nextContext);
      
    const changeLanguageHandler = event => {        
         changeLanguage(event['value']);
        //  console.log('Changing language to : ' + event['value'] + ". Context.language is " + context.language);
    }
    return (
        <div style={{backgroundColor: '#' + props.data.website.color}} className={`h-10 flex flex-row items-center justify-between z-20`}>
            {/* {
                props.showLogo 
                    ? <div className="text-white font-bold inline-block pl-10 text-xl"><Link to='/'>{props.website.name}</Link></div>
                    : <div />
            }             */}
            
            <div />
            <div className="pr-1 tablet:pr-3 desktop:pr-5 flex justify-center items-center">
            {Object.keys(options).length != 1 && <Select isSearchable={false} className="border-none"
                    value={options[context.language]}
                    // value = {context.language}
                    options={Object.values(options)}
                    onChange={changeLanguageHandler}
                    styles={{
                        control: (styles) => ({
                          ...styles,
                          background: '#' + props.data.website.color, // backgroud is the same with main color
                          border: 0,
                          boxShadow: 'none',
                          }),
                        indicatorSeparator: (styles) => ({display:'none'}), 
                        singleValue: (styles) => ({
                            ...styles,
                            verticalAlign: 'middle',
                            display: 'flex',
                            alignItems: 'center',
                            marginRight: 0,
                            marginLeft: 0,                            
                        }),
                        valueContainer: (styles) => ({
                            ...styles,
                            paddingRight: 0,
                            paddingLeft: 0,
                            marginRight: 0,
                            marginLeft: 0,                            
                        }),
                        dropdownIndicator: (styles) => ({
                            ...styles,
                            color: '#FFFFFF',                       
                        })                        
                    }}                   
                />}
                
               
            </div>
        </div>
    );
};

export default HeaderSection;
