import React from "react";
import telephone_icon from "../../images/phone-call.svg";
import email_icon from "../../images/mail_outline-24px.svg";
import { Link, useTranslation } from "gatsby-plugin-react-i18next";

const FooterSectionMobile = (props) => {

    const { t } = useTranslation();

    return (
        <div className="flex flex-col bg-gray-100 mt-20 space-y-10 place-items-center">
            {/* Address */}
            <div className="flex flex-col text-center pt-10 text-[#747474] font-medium">
                <div className=" font-semibold pb-1">{t('Address')}</div>
                <div className="">
                    {props.website.address["street"]} {props.website.address["no"]}
                </div>

                <div className="">
                    {props.website.address["zip_code"]} {props.website.address["city"]}
                </div>
                <div className="flex flex-row justify-center space-x-2 pt-5">
                    <a href={"tel:" + props.website.contact.telephone} target="_blank">
                        <div style={{backgroundColor: `#${props.website.color}`}} className="relative h-8 w-8 rounded-full flex place-items-center place-content-center">
                            <img src={telephone_icon} className="h-4 w-4"></img>
                        </div>
                    </a>
                    <a href={"mailto:" + props.website.contact.email} target="_blank">
                        <div style={{backgroundColor: `#${props.website.color}`}} className="relative h-8 w-8 rounded-full bg-accentColor flex place-items-center place-content-center">
                            <img src={email_icon} className="h-5 w-5"></img>
                        </div>
                    </a>
                </div>
            </div>
            {/* Price Buttons */}
            {/* <div className="flex flex-col space-y-5sm pt-5">
                <ButtonSmall minPriceValue={props.website.min_delivery_value} deliveryCost={props.website.delivery_cost} deliveryTime={props.website.delivery_time} />
            </div> */}

            {/* Copywright */}
            <div className="flex flex-col justify-center space-y-10">
                <div className="px-5">
                    <hr className="bg-gray-900" />
                </div>
                <div className="flex flex-col justify-center ">
                    <div className="flex flex-col  text-center text-sm space-y-2 text-[#747474]">
                        {/* <a className="inline-block pr-4" href={"/ext/legal?" + props.lang}>
                            {tr["legal"][props.lang]}
                        </a> */}
                        {/* <a className="inline-block pr-4" href={"/ext/allergens"}>
                            {tr["allergens"][props.lang]}
                        </a> */}
                        {/* <a className="inline-block pr-4" href={"/imprint/"}>
                            {t('Imprint')}
                        </a>
                        <a className="inline-block pr-4" href={"/privacy/"}>
                            {t('Privacy')}
                        </a>                     */}
                        <Link to="/imprint/" className="inline-block pr-4" >{t('Imprint')}</Link>
                        <Link to="/privacy/" className="inline-block pr-4" >{t('Privacy')}</Link>
                    </div>

                    <div className="text-center text-2xs pt-5 pb-10 text-[#747474]">{t('copyright')}</div>
                </div>
            </div>
        </div>
    );
};

export default FooterSectionMobile;
