import './src/styles/tailwind.css';

const React = require('react');
const Layout = require('./src/Components/Layout').default

export function wrapPageElement({ element, props }) {
    // const Layout = element.type.Layout ?? React.Fragment;
    // return <Layout {...props}>{element}</Layout>;

    const newElement = React.cloneElement(
        element,  // I18nextProvider
        element.props,
        React.cloneElement(
            element.props.children,  // I18nextContext.Provider
            element.props.children.props,
            React.createElement(
                Layout,
                props,
                element.props.children.props.children,
            ),
        )
        ,
    );
    return newElement;
}


