import React from "react";
import telephone_icon from "../../images/phone-call.svg";
import email_icon from "../../images/mail_outline-24px.svg";
import nanea_logo_bottom_right from "../../images/Icon_nanea.svg";
import {Buffer} from 'buffer';
import { Link, useTranslation } from "gatsby-plugin-react-i18next";

// not use now, but it can darken or lightern footer colors according to the main color
// function adjustColor(color, amount) {
//     return "#" + color.replace(/^#/, "").replace(/../g, (color) => ("0" + Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2));
// }

function replaceColorSvg(svgData: string, oldCode: string, newCode: string): string {
    const prefix = 'data:image/svg+xml;base64,';
    const onlyBase64 = svgData.replace(prefix, '');
    const plainTextOld = Buffer.from(onlyBase64, 'base64').toString('ascii');
    const plainTextNew = plainTextOld.replaceAll(oldCode, newCode);
    const result = prefix + Buffer.from(plainTextNew, 'utf8').toString('base64');
    return result;
}

// Note: the text and bg color of the footer is hardcode, so no customization from user side
const FooterSectionDesktop = (props) => {

    const { t } = useTranslation();
    const nanea_logo_bottom_right_new = replaceColorSvg(nanea_logo_bottom_right, '#d6b4b4', '#' + props.website.color);
    return (
        <div className="flex flex-col bg-gray-50 pt-20 mt-20 ">
            <div className="grid grid-cols-2 w-full">
                {/* Address */}
                <div className="flex flex-col text-[#747474] ml-20">
                    <div className="font-bold pb-1">{t('Address')}</div>
                    <div className="">
                        {props.website.address["street"]} {props.website.address["no"]}
                    </div>
                    <div className="">
                        {props.website.address["zip_code"]} {props.website.address["city"]}
                    </div>

                    <div className="flex flex-row space-x-2 mt-5">
                        <a href={"tel:" + props.website.contact.telephone} target="_blank">
                            <div style={{backgroundColor: `#${props.website.color}`}} className="relative h-8 w-8 rounded-full bg-accentColor flex place-items-center place-content-center">
                                <img src={telephone_icon} className="h-4 w-4"></img>
                            </div>
                        </a>
                        <a href={"mailto:" + props.website.contact.email} target="_blank">
                            <div style={{backgroundColor: `#${props.website.color}`}} className="relative h-8 w-8 rounded-full bg-accentColor flex place-items-center place-content-center">
                                <img src={email_icon} className="h-5 w-5"></img>
                            </div>
                        </a>
                    </div>
                </div>
                {/* <div className="flex flex-col text-center  space-y-5 place-self-center">
                    <ButtonSmall minPriceValue={props.website.min_delivery_value} deliveryCost={props.website.delivery_cost} deliveryTime={props.website.delivery_time} />
                </div> */}

                <div className="justify-self-end opacity-30">
                    <img src={nanea_logo_bottom_right_new} alt="Nanea logo"  />
                </div>

                {/* <div className="grid grid-rows-3 flex justify-end ">
                    <div className="bg-accentColor w-40 h-4"></div>

                    <div className="grid grid-rows-1 flex justify-end  ">
                        <div className="bg-accentColor w-20 h-4"></div>
                    </div>
                    <div className="grid grid-rows-1 flex justify-end ">
                        <div className="bg-accentColor w-28 h-4"></div>
                    </div>
                </div> */}
            </div>

            <div className="px-1 pt-20  ml-20  pr-20">
                <hr className="bg-gray-500" />
            </div>

            <div className="grid grid-rows-2  ml-20">
                <div className="flex flex-row justify-between pt-5 text-[#747474] pr-20">
                    <div>{t('copyright')}</div>
                    <div className="flex justify-end text-[#747474] text-sm">
                        {/* <a className="inline-block pr-4" href={"/ext/legal?" + props.lang}>
                            {tr["legal"][props.lang]}
                        </a> */}
                        {/* <a className="inline-block pr-4" href={"/ext/allergens"}>
                            {tr["allergens"][props.lang]}
                        </a> */}
                        {/* <a className="inline-block pr-4" href={"/imprint/"}>
                            {t('Imprint')}
                        </a>
                        <a className="inline-block pr-4" href={"/privacy/"}>
                            {t('Privacy')}
                        </a> */}
                        {/* </div> */}
                        <Link to="/imprint/" className="inline-block pr-4" >{t('Imprint')}</Link>
                        <Link to="/privacy/" className="inline-block pr-4" >{t('Privacy')}</Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FooterSectionDesktop;
