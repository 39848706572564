import React from "react";
import FooterSectionDesktop from "./FooterSectionDesktop";
import FooterSectionMobile from "./FooterSectionMobile";
import FooterSectionTablet from "./FooterSectionTablet";

const Footer = (props) => {
    return (
        <>
            <div className="mobile:hidden tablet:hidden">  <FooterSectionDesktop website={props.website} /></div>
            <div className="desktop:hidden tablet:hidden ">  <FooterSectionMobile website={props.website} /></div>
            <div className="desktop:hidden mobile:hidden ">  <FooterSectionTablet website={props.website} /></div>  
        </>
    );
};

export default Footer;
